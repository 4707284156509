import React from 'react';
import Link from '../atoms/link';

const Footer = ({
	links,
	copyright,
	disclaimer,
	pageDisclaimer = false
}) => {

	const createLinks = () => {
		let linksGroup = [];
        links.map((link, index) => {
            linksGroup.push((
				<Link 
					url={link.url}
					classNames="co-footer__link" 
					target="_blank"
					external
					key={index}
				>{link.label}</Link>
            ));
        })
        return linksGroup;
	}

	const leftColumn = (
		<div className="co-footer__logo">
			<Link url="/" type="image">
				<img src="https://prestamos-de-negocio.com/assets/images/logo-white.png" alt="logo" />
			</Link>
		</div>
	);

	const rightColumn = (
		<div className='l-grid__col-md co-footer__links--secondary'>
			<div className="l-grid__row l-grid__end-xs">
				{createLinks()}
			</div>
		</div>
	);

	const copyrightContent = (
		<div className='l-grid__row'>
			<div className='l-grid__col-xs'>
				<p>&copy; Copyright {new Date().getFullYear()} <span dangerouslySetInnerHTML={{ __html: copyright }}></span></p>
			</div>
		</div>
	);

	const disclaimerContent = (
		<div className='l-grid__row'>
			<div className='l-grid__col-xs'>
				<p dangerouslySetInnerHTML={{ __html: disclaimer }}></p>
			</div>
		</div>
	);

	const pageDisclaimerContent = (pageDisclaimer) ? (
		<div className='l-grid__row l-spacing__m-t--24 co-footer__page-disclaimer'>
			<div className='l-grid__col-xs'>
				<p>{pageDisclaimer}</p>
			</div>
		</div>
	) : null;

	return (
		<div className='co-footer'>
			<div className='co-footer__main'>
				<div className='l-container'>
					<div className='l-grid__row l-grid__middle-xs co-footer__logo-links'>
						{leftColumn}
						{rightColumn}
					</div>
				</div>
			</div>
			<div className='co-footer__copyright-disclaimer'>
				<div className='l-container'>
					{copyrightContent}
					{disclaimerContent}
					{pageDisclaimerContent}
				</div>
			</div>
		</div>
	);
};

export default Footer;
